<template>
    <a-modal title="Company's offre" visible :onCancel="handleCancel" okText="Done" @ok="onSubmit">
        <div>
            <FormValidationWrap>
                <VerticalFormStyleWrap>
                    <a-form name="sDash_validation-form" ref="offreFormRef" @finish="onSubmit" :model="formState"
                        :rules="rules" :layout="formState.layout">
                        <a-row :gutter="25">
                            <a-col :span="12">
                                <a-form-item label="Contact Person" name="contactPersonId">
                                    <a-select :disabled="readOnly" v-model:value="formState.contactPersonId"
                                        :options="partnerContactList" placeholder="Contact Person"
                                        :filter-option="filterOption" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12" :xs="12">
                                <a-form-item name="partnerOffreDate" label="Date">
                                    <a-date-picker :disabled="readOnly" v-model:value="formState.partnerOffreDate"
                                        :style="{ width: '100%' }" :format="dateFormat" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Quantity" name="quantity">
                                    <a-input-number :disabled="readOnly" :style="{ width: '100%' }"
                                        v-model:value="formState.quantity" placeholder="Quantity" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="UOM" name="unitOfMeasureId">
                                    <a-select :disabled="readOnly" v-model:value="formState.unitOfMeasureId"
                                        placeholder="Please choose a unit">
                                        <a-select-option v-for="(unit, index) in UnitApi" :key="index" :value="unit.id">
                                            {{ unit.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Price" name="price">
                                    <a-input-number :disabled="readOnly" :style="{ width: '100%' }"
                                        v-model:value="formState.price" placeholder="Price" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Currency" name="currencyId">
                                    <a-select :disabled="readOnly" v-model:value="formState.currencyId"
                                        placeholder="currency">
                                        <a-select-option v-for="(currency, index) in CurrencyApi" :key="index"
                                            :value="currency.id">
                                            {{ currency.name }}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="24" :xs="24">
                                <div class="ant-col ant-form-item-label" style="margin-bottom: -18px">
                                    <label title="Payment terms">Payment terms<!----></label>
                                </div>
                                <PaymentTerms :readOnlyMode="readOnly" :partnerRequest="partner"
                                    :partnerInspectionRequestId="partnerInspectionRequestId"
                                    :partnerTransportRequestId="partnerTransportRequestId" />
                            </a-col>
                            <a-col :span="24" :xs="24">
                                <a-form-item ref="ExpensePurchase" label="Offre File" name="ExpensePurchase"
                                    style="margin-top:14px;">
                                    <a-upload v-if="!readOnly" v-model:file-list="offreFileList" name="ExpensePurchase"
                                        :multiple="false" :customRequest="uploadOffreFile" :style="{ width: '100%' }"
                                        :showUploadList="false">
                                        <a-button class="btn-outlined" size="large" :outlined="true"
                                            v-if="offreFileList.length != 1">
                                            <upload-outlined></upload-outlined>
                                            <span>Select Files</span>
                                        </a-button>
                                    </a-upload>
                                    <div v-if="offreFileList.length">
                                        <div v-for="file in offreFileList" :key="file.uid" class="file-item">
                                            <span class="file-text" :title="file.fileName">{{ file.fileName
                                                }}</span>
                                            <a @click="handleDownload(file)">
                                                <sdFeatherIcons type="download" />
                                            </a>
                                            <a v-if="!readOnly" @click="removeFile(file)">
                                                <sdFeatherIcons type="trash-2" />
                                            </a>
                                        </div>
                                    </div>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-form>
                </VerticalFormStyleWrap>
            </FormValidationWrap>
        </div>

    </a-modal>
</template>
<script setup>
import { useStore } from "vuex";
import { reactive, ref, onMounted, defineEmits, defineProps, computed, defineAsyncComponent } from "vue";
import { useFileDownload } from '@/composable/useFileDownload';
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "@/view/forms/overview/Style";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";
import moment from "moment";

const { state, dispatch } = useStore();
const { downloadFile } = useFileDownload();
const props = defineProps(['partner', 'partnerInspectionRequestId', 'partnerTransportRequestId', "readOnly"]);

const PaymentTerms = defineAsyncComponent(() => import("@/common/components/PaymentTerms.vue"));
/// Apiss data
const offreFileList = computed(() => {
    let list = [];
    if (props.partner.file) {
        list.push(props.partner.file);
    }
    return list;
});
const UnitApi = computed(() => state.UnitApi.data);
const CurrencyApi = computed(() => state.CurrencyApi.data);
////
const emit = defineEmits(['cancel', 'update-offre', 'upload-offre-file', 'delete-file'])

//form
const dateFormat = "YYYY/MM/DD";

const rules = {
    partnerOffreDate: [
        {
            type: "object",
            required: true,
            message: "Date is required",
            trigger: "change",
        },
    ],
    price: [
        {
            type: "number",
            required: true,
            message: "Price is required",
            trigger: "change",
        },
    ],
    currencyId: [
        {
            required: true,
            message: "Currency is required",
            trigger: "change",
        },
    ],
    unitOfMeasureId: [
        {
            required: true,
            message: "Unit is required",
            trigger: "change",
        },
    ],
    quantity: [
        {
            type: "number",
            required: true,
            message: "Quantity is required",
            trigger: "change",
        },
    ],
    contactPersonId: [
        {
            required: true,
            message: "Contact person is required",
            trigger: "change",
        },
    ],
};
const offreFormRef = ref();
var formState = reactive({
    layout: "vertical",
    id: 0,
    contactPersonId: "",
    partnerOffreDate: null,
    partnerOffreIsValid: false,
});

onMounted(() => {
    dispatch("unitInfo");
    dispatch("currencyInfo");
    fetchPartnerContacts();
    formState.id = props.partnerInspectionRequestId ?? props.partnerTransportRequestId;
    formState.partnerOffreDate = props.partner.partnerOffreDate ? moment(props.partner.partnerOffreDate) : null;
    formState.partnerOffreIsValid = props.partner.partnerOffreIsValid;
    formState.price = props.partner.price;
    formState.currencyId = props.partner.currencyId;
    formState.contactPersonId = props.partner.contactPersonId;
    formState.unitOfMeasureId = props.partner.unitOfMeasureId;
    formState.quantity = props.partner.quantity;
});

const uploadOffreFile = (file) => {
    emit('upload-offre-file', file)
};
const onSubmit = async () => {
    if (!props.readOnly) {
        offreFormRef.value
            .validate()
            .then(() => {
                emit('update-offre', formState);
            })
            .catch((error) => {
                console.log("error", error);
            });
    } else {
        handleCancel();
    }
};
const handleDownload = async (file) => {
    // Pass the file ID to initiate download
    await downloadFile(file);
};
const removeFile = (file) => {
    dispatch("removeFile", file);
    offreFileList.value = [];

    emit("delete-file");
};

const handleCancel = () => {
    // if (offreFileList.value.length && formState.id) {
    //     let files = offreFileList.value;
    //     let id = formState.id;
    //     dispatch("updateoffreFileList", { files, id });
    // }
    emit('cancel');
}

// Partner contact option
const partnerContactList = ref([]);

const filterOption = (input, option) => {
    return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
};

const fetchPartnerContacts = async () => {
    try {
        const query = await DataService.get(
            `api/PartenaireContact/GetPartenaireContactNames?PartenaireId=${props.partner.partnerId}`
        );

        if (query.succeeded) {
            partnerContactList.value = query.data.map(contact => ({ value: contact.id, label: contact.fullName }));
        }
    } catch (error) {
        notification.error({
            message: "Error while fetching partner contact.",
        });
    }
};
</script>

<style scoped>
.file-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-item {
    margin-top: 4px;
    display: flex;
}

.file-item a,
.file-item span {
    margin-left: 8px;
    cursor: pointer;

    &:nth-child(2) {
        color: #1890ff !important;
    }

    &:last-child {
        color: red !important;
    }
}
</style>